import {
  getAdminProfile,
  changeAccountPassword,
  checkLoginOtp,
  loginIndividual,
  logoutIndividual,
  checkEmail,
  checkEmailOtp,
  changePassword,
} from '@/api/auth.api';
import { deserializer, serializer } from '@/helpers/api';
import { AdminRoles, UserRoles } from '@/constants/enums';
import { ErrorHandler } from '@/helpers';

const handleResetPasswordConfig = (config) => ({
  otpToken: config.otpToken,
  accountId: config.accountId,
});

const handleResetToken = (resetTokenOptions) => resetTokenOptions.resetToken;

const mainState = {
  loginForm: {},
  loading: false,
  context: null,
  userProfile: {},
  otpLoading: false,
  resetPasswordConfig: null,
  resetToken: null,
  resetingEmail: '',
};

const mainGetters = {
  loginForm: (store) => store.loginForm,
  userProfile: (store) => store.userProfile,
  loading: (store) => store.loading,
  isAuthenticated: (store) => Boolean(store.context?.accountId),
  adminRole: (store) => store.context?.role,
  isSupervisoryRole: (store, getters) => getters.adminRole === AdminRoles.Supervisory,
  isGovernanceRole: (store, getters) => getters.adminRole === AdminRoles.GovernanceAdmin,
  isSpecializedRole: (store, getters) => (getters.adminRole === AdminRoles.Specialized),
  isMinistryOfInteriorRole: (store, getters) => (
    getters.adminRole === AdminRoles.MinistryOfInteriorEmployee
  ),
  isGovernanceCenterEmployee: (store, getters) => (
    getters.adminRole === AdminRoles.GovernanceCenterEmployee),
  accountId: (store) => store.context?.accountId,
  updatedAt: (store) => store.context?.updatedAt,
  departmentId: (store) => store.context?.departments,
  otpLoading: (store) => store.otpLoading,
  resetPasswordConfig: (store) => store.resetPasswordConfig,
  resetingEmail: (store) => store.resetingEmail,
  resetToken: (store) => store.resetToken,
};

const mainMutations = {
  setLoading: (store, value) => {
    store.loading = value;
  },
  setOtpLoading: (store, value) => {
    store.otpLoading = value;
  },
  setUserProfile: (store, value) => {
    store.userProfile = value;
  },
  setContext: (store, value) => {
    store.context = value;
  },
  setLoginForm: (store, form) => {
    store.loginForm = {
      ...store.loginForm,
      ...form,
    };
  },
  setResetPasswordConfig: (store, config) => {
    store.resetPasswordConfig = config;
  },
  setResetToken: (store, resetToken) => {
    store.resetToken = resetToken;
  },
  setResetingEmail: (store, email) => {
    store.resetingEmail = email;
  },
  resetPasswordConfigData: (store) => {
    store.resetPasswordConfig = null;
    store.resetToken = null;
    store.resetingEmail = '';
  },
};

const mainActions = {
  loginIndividual: async ({ commit, state }) => {
    const { email, password } = state.loginForm;
    const data = serializer(
      {
        password,
        email,
        space: 'admin',
      },
      'login',
    );
    commit('setLoading', true);
    try {
      return await loginIndividual(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e, 'Network Error');
    } finally {
      commit('setLoading', false);
    }
  },
  checkLoginOtp: async ({ commit }, code) => {
    const data = serializer(
      {
        otp: code,
      },
      'otp',
    );

    commit('setOtpLoading', true);
    try {
      const res = await checkLoginOtp(data);
      const context = await deserializer(res.data);
      commit('setContext', {
        accountId: context.accountId,
        language: context.language,
        role: context.role,
        departments: context.departments,
        updatedAt: context.updatedAt,
      });
      return context;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setOtpLoading', false);
    }
  },
  logoutIndividual: ({ commit }) => {
    try {
      logoutIndividual(serializer({}, 'logout'));
      commit('requests/resetStoreToDefaultState', null, { root: true });
    } catch (e) {
      console.warn(e);
    }
    commit('setContext', null);
  },
  getAdminProfile: async ({ commit }) => {
    commit('setLoading', true);
    try {
      const res = await getAdminProfile();
      const account = res?.data?.profile || {};
      if (account && account.role !== UserRoles.Individual) {
        commit('setContext', {
          accountId: account.accountId,
          role: account.role,
          departments: account.departments,
          updatedAt: account.updatedAt,
        });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setTimeout(() => {
        commit('setLoading', false);
      }, 300);
    }
  },
  async changeAccountPassword({ commit }, newPassword) {
    const data = {
      password: newPassword,
    };
    commit('setLoading', true);
    try {
      return await changeAccountPassword(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      setTimeout(() => {
        commit('setLoading', false);
      }, 300);
    }
  },
  // eslint-disable-next-line consistent-return
  async checkEmail({ commit }, email) {
    commit('setLoading', true);
    try {
      const resp = await checkEmail(email);
      commit('setResetPasswordConfig', handleResetPasswordConfig(resp.data.checkEmail));
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  // eslint-disable-next-line consistent-return
  async checkEmailOtp({ commit }, { otp, otpToken, accountId }) {
    commit('setLoading', true);
    try {
      const resp = await checkEmailOtp({ otp, otpToken, accountId });
      commit('setResetToken', handleResetToken(resp.data.checkEmailOtp));
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  // eslint-disable-next-line consistent-return
  async changePassword({ commit }, { accountId, password, resetToken }) {
    commit('setLoading', true);
    try {
      await changePassword({ accountId, password, resetToken });
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('resetPasswordConfigData');
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
