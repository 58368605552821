<template>
  <div
    v-if="toastList.length"
    class="toaster"
  >
    <toast
      v-for="notification in toastList"
      :key="notification.id"
      :notification="notification"
      @close="close"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ToastContainer',
  computed: {
    ...mapGetters('toast', [
      'toastList',
    ]),
  },
  methods: {
    ...mapMutations('toast', [
      'removeNotification',
    ]),
    close({ id }) {
      this.removeNotification(id);
    },
  },
};
</script>

<style lang="scss">
  .toaster {
    @include list-reset;
    max-width: 350px;
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: z(modal) + 1;
  }
</style>
