import DashboardLayout from '@/views/dashboard/DashboardLayout.vue';
import { AdminRoles } from '@/constants/enums';

export default {
  path: '',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'Dashboard',
      component: () => import(
        /* webpackChunkName: "dashboard-main" */
        '../views/dashboard/dashboard-main/DashboardMain.vue'
      ),
      meta: {
        roles: Object.values(AdminRoles),
      },
    },
    {
      path: 'admin-list/:adminType',
      name: 'AdminList',
      component: () => import(
        /* webpackChunkName: "admin-list" */
        '../views/dashboard/admin-fabric/admin-list-fabric/AdminListFabric.vue'
      ),
      meta: {
        roles: [AdminRoles.SuperAdmin],
      },
    },
    {
      path: 'create-admin/:adminType',
      name: 'CreateAdminFabric',
      component: () => import(
        /* webpackChunkName: "create-admin" */
        '../views/dashboard/admin-fabric/create-admin-fabric/CreateAdminFabric.vue'
      ),
      meta: {
        roles: [AdminRoles.SuperAdmin],
      },
    },
    {
      path: 'entity-requests',
      name: 'EntityRequests',
      component: () => import(
        /* webpackChunkName: "entity-requests" */
        '../views/dashboard/requests/requests-list/establishment-requests/EstablishmentEntityRequests.vue'
      ),
      redirect: { name: 'NewEntityRequests' },
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
          AdminRoles.HrsdAdmin,
          AdminRoles.HrsdEmployee,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitManager,
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitCeo,
        ],
      },
      children: [
        {
          path: 'new-requests',
          name: 'NewEntityRequests',
          component: () => import(
            /* webpackChunkName: "new-entity-request" */
            '../views/dashboard/requests/requests-list/establishment-requests/requests-table/RequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'EntityRequestsHistory',
          component: () => import(
            /* webpackChunkName: "entity-requests-history" */
            '../views/dashboard/requests/requests-list/establishment-requests/requests-table/RequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'unified-national-number-requests',
      name: 'UnifiedNationalNumberRequests',
      component: () => import(
        /* webpackChunkName: "unified-national-number-requests" */
        '../views/dashboard/requests/requests-list/unified-national-number-requests/UnifiedNationalNumberRequests.vue'
      ),
      redirect: { name: 'NewUnifiedNationalNumberRequests' },
      meta: {
        roles: [
          AdminRoles.RegistrationAndPermitEmployee,
        ],
      },
      children: [
        {
          path: 'new-unified-national-number-requests',
          name: 'NewUnifiedNationalNumberRequests',
          component: () => import(
            /* webpackChunkName: "new-unified-national-number-requests" */
            '../views/dashboard/requests/requests-list/unified-national-number-requests/requests-table/RequestsTable.vue'
          ),
        },
        {
          path: 'unified-national-number-history',
          name: 'EntityUnifiedNationalNumberHistory',
          component: () => import(
            /* webpackChunkName: "unified-national-number-history" */
            '../views/dashboard/requests/requests-list/unified-national-number-requests/requests-table/RequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'unified-national-number-details/:requestId',
      name: 'UnifiedNationalNumberDetails',
      component: () => import(
        /* webpackChunkName: "objection-details" */
        '../views/dashboard/requests/request-details/unified-national-number-request/UnifiedNationalNumberDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.RegistrationAndPermitEmployee],
      },
    },
    {
      path: 'unified-national-number-summary/:requestId',
      name: 'UnifiedNumberSummary',
      component: () => import(
        /* webpackChunkName: "objection-details" */
        '../views/dashboard/requests/request-details/unified-national-number-request/sections/request-unified-number-summary'
      ),
      meta: {
        roles: [AdminRoles.RegistrationAndPermitEmployee],
      },
    },
    {
      path: 'branch-requests',
      name: 'BranchRequests',
      component: () => import(
        /* webpackChunkName: "branch-requests" */
        '../views/dashboard/requests/requests-list/establishment-branch-requests/EstablishmentBranchRequests.vue'
      ),
      redirect: { name: 'NewBranchRequests' },
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.GovernanceCenterEmployee,
          AdminRoles.GovernanceAdmin,
        ],
      },
      children: [
        {
          path: 'new-requests',
          name: 'NewBranchRequests',
          component: () => import(
            /* webpackChunkName: "new-entity-request" */
            '../views/dashboard/requests/requests-list/establishment-branch-requests/requests-table/RequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'BranchRequestsHistory',
          component: () => import(
            /* webpackChunkName: "entity-requests-history" */
            '../views/dashboard/requests/requests-list/establishment-branch-requests/requests-table/RequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'entity-activities-requests',
      name: 'EntityActivitiesRequests',
      component: () => import(
        /* webpackChunkName: "entity-activities-requests" */
        '../views/dashboard/requests/requests-list/entity-activities-requests/EntityActivitiesRequests.vue'
      ),
      redirect: { name: 'NewEntityActivityRequests' },
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.GovernanceAdmin,
          AdminRoles.HrsdEmployee,
          AdminRoles.GovernanceCenterEmployee,
        ],
      },
      children: [
        {
          path: 'new',
          name: 'NewEntityActivityRequests',
          component: () => import(
            /* webpackChunkName: "new-entity-activity-requests" */
            '../views/dashboard/requests/requests-list/entity-activities-requests/requests-table/RequestsTable.vue'
          ),
        },
        {
          path: 'history',
          name: 'EntityActivityRequestsHistory',
          component: () => import(
            /* webpackChunkName: "entity-activity-requests-history" */
            '../views/dashboard/requests/requests-list/entity-activities-requests/requests-table/RequestsTable.vue'
          ),
        },
        {
          path: 'accepted',
          name: 'EntityActivityAcceptedRequests',
          component: () => import(
            /* webpackChunkName: "entity-activity-accepted-requests" */
            '../views/dashboard/requests/requests-list/entity-activities-requests/requests-table/RequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'bank-delegations-requests',
      name: 'BankRequests',
      redirect: { name: 'ReceivedBankRequests' },
      component: () => import(
        /* webpackChunkName: "requests-list" */
        '../views/dashboard/requests/requests-list/bank-delegations-requests/BankDelegationsRequests.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
        ],
      },
      children: [
        {
          path: 'received-requests',
          name: 'ReceivedBankRequests',
          component: () => import(
            /* webpackChunkName: "received-requests" */
            '../views/dashboard/requests/requests-list/bank-delegations-requests/delegation-requests-table/BankDelegationsRequestsTable.vue'
          ),
        },
        {
          path: 'previous-requests',
          name: 'PreviousBankRequests',
          component: () => import(
            /* webpackChunkName: "previous-requests" */
            '../views/dashboard/requests/requests-list/bank-delegations-requests/delegation-requests-table/BankDelegationsRequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'bank-delegations-request-details/:requestId',
      name: 'BankRequestsDetails',
      component: () => import(
        /* webpackChunkName: "requests-list" */
        '../views/dashboard/requests/request-details/bank-delegations/BankDelegationRequest.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
        ],
      },
    },
    {
      path: 'eshop-requests',
      name: 'EshopRequests',
      component: () => import(
        /* webpackChunkName: "eshop-requests" */
        '../views/dashboard/requests/requests-list/eshop-requests/EshopRequests.vue'
      ),
      redirect: { name: 'EshopNewRequests' },
      meta: {
        roles: [AdminRoles.RegistrationAndPermitAdmin, AdminRoles.RegistrationAndPermitEmployee],
      },
      children: [
        {
          path: 'new-requests',
          name: 'EshopNewRequests',
          component: () => import(
            /* webpackChunkName: "eshop-new-request" */
            '../views/dashboard/requests/requests-list/eshop-requests/eshop-requests-table/EshopRequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'EshopRequestsHistory',
          component: () => import(
            /* webpackChunkName: "eshop-requests-history" */
            '../views/dashboard/requests/requests-list/eshop-requests/eshop-requests-table/EshopRequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'eshop-request-details/:adminRequestId',
      name: 'EshopRequestsDetails',
      component: () => import(
        /* webpackChunkName: "eshop-request-details" */
        '../views/dashboard/requests/request-details/eshop-request/EshopRequestDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.RegistrationAndPermitAdmin, AdminRoles.RegistrationAndPermitEmployee],
      },
    },
    {
      path: 'family-request-details/:requestId',
      name: 'FamilyRequestDetails',
      component: () => import(
        /* webpackChunkName: "family-request-details" */
        '../views/dashboard/requests/request-details/family-fund-request/FamilyFundWrapperDetails.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.Specialized,
          AdminRoles.HrsdAdmin,
          AdminRoles.MinistryOfInteriorEmployee,
          AdminRoles.HrsdEmployee,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitManager,
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitCeo,
        ],
      },
    },
    {
      path: 'request-details-entity/:entityId/:requestId',
      name: 'RequestDetailsEntity',
      component: () => import(
        /* webpackChunkName: "request-details-entity" */
        '../views/dashboard/requests/request-details/establishment-request/RequestDetails.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
          AdminRoles.HrsdAdmin,
          AdminRoles.HrsdEmployee,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitManager,
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitCeo,
        ],
      },
    },
    {
      path: 'request-details-branch/:entityId/:requestId',
      name: 'RequestDetailsBranch',
      component: () => import(
        /* webpackChunkName: "request-details-branch" */
        '../views/dashboard/requests/request-details/establishment-request/RequestDetails.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.GovernanceCenterEmployee,
          AdminRoles.GovernanceAdmin,
        ],
      },
    },
    {
      path: 'employee-requests',
      name: 'EmployeeRequests',
      component: () => import(
        /* webpackChunkName: "employee-requests" */
        '../views/dashboard/requests/requests-list/employee-requests/EmployeeRequests.vue'
      ),
      redirect: { name: 'EmployeeNewRequests' },
      meta: {
        roles: [
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
          AdminRoles.HrsdEmployee,
          AdminRoles.GovernanceAdmin,
        ],
      },
      children: [
        {
          path: 'new-requests',
          name: 'EmployeeNewRequests',
          component: () => import(
            /* webpackChunkName: "eshop-new-request" */
            '../views/dashboard/requests/requests-list/employee-requests/employee-requests-table/EmployeeRequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'EmployeeRequestsHistory',
          component: () => import(
            /* webpackChunkName: "eshop-requests-history" */
            '../views/dashboard/requests/requests-list/employee-requests/employee-requests-table/EmployeeRequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'employee-requests/employee-request-details/:requestId',
      name: 'EmployeeRequestDetails',
      component: () => import(
        /* webpackChunkName: "employee-request-details" */
        '../views/dashboard/requests/request-details/employee-request/EmployeeRequestsDetails.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
          AdminRoles.HrsdEmployee,
          AdminRoles.GovernanceAdmin,
        ],
      },
    },
    {
      path: 'edit-policy-requests',
      name: 'EditPolicyRequests',
      component: () => import(
        /* webpackChunkName: "edit-requests-list" */
        '../views/dashboard/requests/requests-list/edit-policy-requests/EditPolicyRequests.vue'
      ),
      redirect: { name: 'NewRequests' },
      meta: {
        roles: [AdminRoles.Supervisory, AdminRoles.GovernanceAdmin],
      },
      children: [
        {
          path: 'new-requests',
          name: 'NewRequests',
          component: () => import(
            /* webpackChunkName: "new-request" */
            '../views/dashboard/requests/requests-list/edit-policy-requests/edit-policy-requests-table/EditPolicyRequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'RequestsHistory',
          component: () => import(
            /* webpackChunkName: "requests-history" */
            '../views/dashboard/requests/requests-list/edit-policy-requests/edit-policy-requests-table/EditPolicyRequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'edit-policy-requests/:id/edit-items/:editRequestId',
      name: 'EditPolicyRequestDetails',
      component: () => import(
        /* webpackChunkName: "edit-policy-request-details" */
        '../views/dashboard/requests/request-details/edit-policy-request/EditPolicyRequestDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.Supervisory, AdminRoles.GovernanceAdmin],
      },
    },
    {
      path: 'references',
      name: 'References',
      component: () => import(
        /* webpackChunkName: "references" */
        '../views/dashboard/references/References.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Specialized,
          AdminRoles.MinistryOfInteriorEmployee,
        ],
      },
    },
    {
      path: 'temporary-bank-account-requests',
      name: 'TemporaryBankAccountRequests',
      component: () => import(
        /* webpackChunkName: "temporary-bank-account-requests" */
        '../views/dashboard/requests/requests-list/temporary-bank-account-requests/TemporaryBankAccountRequests.vue'
      ),
      meta: {
        roles: [AdminRoles.HrsdEmployee],
      },
      redirect: { name: 'TemporaryBankAccountNewRequests' },
      children: [
        {
          path: 'new-requests',
          name: 'TemporaryBankAccountNewRequests',
          component: () => import(
            /* webpackChunkName: "new-request" */
            '../views/dashboard/requests/requests-list/temporary-bank-account-requests/temporary-bank-account-requests-table/TemporaryBankAccountRequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'TemporaryBankAccountRequestsHistory',
          component: () => import(
            /* webpackChunkName: "requests-history" */
            '../views/dashboard/requests/requests-list/temporary-bank-account-requests/temporary-bank-account-requests-table/TemporaryBankAccountRequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'temporary-bank-account-request-details/:requestId/:entityId',
      name: 'TemporaryBankAccountRequestDetails',
      component: () => import(
        /*  webpackChunkName: "temporary-bank-account-request" */
        '../views/dashboard/requests/request-details/temporary-bank-account-request/TemporaryBankAccountRequestDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.HrsdEmployee],
      },
    },
    {
      path: 'entity-activities-request-details/:requestId',
      name: 'EntityActivitiesRequestDetails',
      component: () => import(
        /* webpackChunkName: "entity-activities-request-details" */
        '../views/dashboard/requests/request-details/entity-activities-request/EntityActivitiesRequestDetails.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.GovernanceAdmin,
          AdminRoles.HrsdEmployee,
          AdminRoles.GovernanceCenterEmployee,
        ],
      },
    },
    {
      path: 'entity-activities-request-details/:requestId',
      name: 'EntityActivitiesHistoryRequestDetails',
      component: () => import(
        /* webpackChunkName: "entity-activities-request-details" */
        '../views/dashboard/requests/request-details/entity-activities-request/EntityActivitiesRequestDetails.vue'
      ),
      meta: {
        roles: [
          AdminRoles.Supervisory,
          AdminRoles.GovernanceAdmin,
          AdminRoles.HrsdEmployee,
          AdminRoles.GovernanceCenterEmployee,
        ],
      },
    },
    {
      path: 'activities-request-details/:requestId',
      name: 'EntityActivitiesAcceptedRequestDetails',
      component: () => import(
        /* webpackChunkName: "activities-request-details" */
        '../views/dashboard/requests/request-details/entity-activities-request/EntityActivitiesRequestDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.Supervisory, AdminRoles.GovernanceAdmin],
      },
    },
    {
      path: 'updating-entities-requests',
      name: 'UpdatingEntitiesRequests',
      component: () => import(
        /* webpackChunkName: "updating-entities-requests" */
        '../views/dashboard/requests/requests-list/updating-entities-requests/UpdatingEntitiesRequests.vue'
      ),
      redirect: { name: 'UpdatingEntitiesNewRequests' },
      meta: {
        roles: [AdminRoles.AuditOfficer],
      },
      children: [
        {
          path: 'new-requests',
          name: 'UpdatingEntitiesNewRequests',
          component: () => import(
            /* webpackChunkName: "updating-entities-new-request" */
            '../views/dashboard/requests/requests-list/updating-entities-requests/table/RequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'UpdatingEntitiesRequestsHistory',
          component: () => import(
            /* webpackChunkName: "updating-entities-requests-history" */
            '../views/dashboard/requests/requests-list/updating-entities-requests/table/RequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'updating-entities-request-details/:entityId/:requestId',
      name: 'UpdatingEntitesRequestDetails',
      component: () => import(
        /* webpackChunkName: "updating-entities-request-details" */
        '../views/dashboard/requests/request-details/updating-entities-requests/UpdatingEntitiesRequestsDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.AuditOfficer],
      },
    },
    {
      path: 'branch-questions',
      name: 'BranchQuestions',
      component: () => import(
        /* webpackChunkName: "questions" */
        '../views/dashboard/questions/Questions.vue'
      ),
      redirect: { name: 'BranchSendingQuestion' },
      meta: {
        roles: [AdminRoles.Supervisory],
      },
      children: [
        {
          path: 'sending-question/:id/:requestId',
          name: 'BranchSendingQuestion',
          component: () => import(
            /* webpackChunkName: "sending-question" */
            '../views/dashboard/questions/sending-question/SendingQuestion.vue'
          ),
        },
      ],
    },
    {
      path: 'questions',
      name: 'Questions',
      component: () => import(
        /* webpackChunkName: "questions" */
        '../views/dashboard/questions/Questions.vue'
      ),
      redirect: { name: 'SendingQuestion' },
      meta: {
        roles: [
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitCeo,
          AdminRoles.Supervisory,
        ],
      },
      children: [
        {
          path: 'sending-question/:id/:requestId',
          name: 'SendingQuestion',
          component: () => import(
            /* webpackChunkName: "sending-question" */
            '../views/dashboard/questions/sending-question/SendingQuestion.vue'
          ),
        },
        {
          path: 'received-question/:id/:requestId',
          name: 'ReceivedQuestion',
          component: () => import(
            /* webpackChunkName: "received-question" */
            '../views/dashboard/questions/received-question/ReceivedQuestion.vue'
          ),
        },
      ],
    },
    {
      path: 'edit-request/:id',
      name: 'EditRequest',
      component: () => import(
        /* webpackChunkName: "edit-request" */
        '../views/dashboard/edit-request/EditRequest.vue'
      ),
      meta: {
        roles: [
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitCeo,
        ],
      },
    },
    {
      path: 'create-unified-national-number/:id',
      name: 'CreateUnifiedNationalNumberRequest',
      component: () => import(
        /* webpackChunkName: "edit-request" */
        '../views/dashboard/create-unified-national-number-request/CreateUnifiedNationalNumberRequest.vue'
      ),
      meta: {
        roles: [
          AdminRoles.RegistrationAndPermitEmployee,
        ],
      },
    },
    {
      path: 'entities-list',
      name: 'EntitiesList',
      component: () => import(
        /* webpackChunkName: "established-entities" */
        '../views/dashboard/entities/EntitiesList.vue'
      ),
      meta: {
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitManager,
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.GovernanceAdmin,
        ],
      },
    },
    {
      path: 'entity-profile/:id',
      component: () => import(
        /* webpackChunkName: "entity-profile" */
        '../views/dashboard/entity-profile/EntityProfile.vue'
      ),
      meta: {
        roles: [
          AdminRoles.SuperAdmin,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitManager,
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.GovernanceAdmin,
        ],
      },
      children: [
        {
          path: '',
          redirect: { name: 'EntityMain' },
        },
        {
          path: 'main',
          name: 'EntityMain',
          component: () => import(
            /* webpackChunkName: "entity-main" */
            '../containers/entity-profile-tabs/main-tab/MainTab.vue'
          ),
        },
        {
          path: 'members',
          name: 'EntityMembers',
          component: () => import(
            /* webpackChunkName: "entity-members" */
            '../containers/entity-profile-tabs/members-tab/MembersTab.vue'
          ),
        },
        {
          path: 'employees',
          name: 'EntityEmployees',
          component: () => import(
            /* webpackChunkName: "entity-employees" */
            '../containers/entity-profile-tabs/employees-tab/EmployeesTab.vue'
          ),
          props: { theme: 'dashboard' },
        },
        {
          path: 'contacts',
          name: 'EntityContact',
          component: () => import(
            /* webpackChunkName: "entity-contact" */
            '../containers/entity-profile-tabs/contact-tab/ContactTab.vue'
          ),
        },
        {
          path: 'documents',
          name: 'EntityDocuments',
          component: () => import(
            /* webpackChunkName: "entity-documents" */
            '../containers/entity-profile-tabs/documents-tab/DocumentsTab.vue'
          ),
        },
      ],
    },
    {
      path: 'objection-requests',
      name: 'ObjectionRequests',
      component: () => import(
        /* webpackChunkName: "objection-requests" */
        '../views/dashboard/requests/requests-list/objection-requests/ObjectionRequests.vue'
      ),
      redirect: { name: 'NewObjectionRequests' },
      meta: {
        roles: [AdminRoles.RegistrationAndPermitAdmin, AdminRoles.RegistrationAndPermitEmployee],
      },
      children: [
        {
          path: 'new-requests',
          name: 'NewObjectionRequests',
          component: () => import(
            /* webpackChunkName: "objection-new-requests" */
            '../views/dashboard/requests/requests-list/objection-requests/requests-table/RequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'ObjectionRequestsHistory',
          component: () => import(
            /* webpackChunkName: "objection-requests-history" */
            '../views/dashboard/requests/requests-list/objection-requests/requests-table/RequestsTable.vue'
          ),
        },
      ],
    },
    {
      path: 'objection-details/:requestId',
      name: 'ObjectionDetails',
      component: () => import(
        /* webpackChunkName: "objection-details" */
        '../views/dashboard/requests/request-details/objection-request/ObjectionRequestDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.RegistrationAndPermitEmployee, AdminRoles.RegistrationAndPermitAdmin],
      },
    },
    {
      path: 'bank-delegation-requests',
      name: 'BankDelegationRequests',
      redirect: { name: 'NewBankDelegationRequests' },
      component: () => import(
        /* webpackChunkName: "bank-delegation-requests" */
        '../views/dashboard/requests/requests-list/bank-delegation-requests/BankDelegationRequests.vue'
      ),
      children: [
        {
          path: 'new-requests',
          name: 'NewBankDelegationRequests',
          component: () => import(
            /* webpackChunkName: "objection-new-requests" */
            '../views/dashboard/requests/requests-list/bank-delegation-requests/bank-delegation-requests-table/BankDelegationTequestsTable.vue'
          ),
        },
        {
          path: 'requests-history',
          name: 'HistoryBankDelegationRequests',
          component: () => import(
            /* webpackChunkName: "objection-requests-history" */
            '../views/dashboard/requests/requests-list/bank-delegation-requests/bank-delegation-requests-table/BankDelegationTequestsTable.vue'
          ),
        },
      ],
      meta: {
        roles: [AdminRoles.GovernanceCenterEmployee],
      },
    },
    {
      path: 'bank-delegation-request-details/:bankDelegationAdminRequestId',
      name: 'BankDelegationRequestDetails',
      component: () => import(
        /* webpackChunkName: "" */
        '../views/dashboard/requests/request-details/bank-delegation-request/BankDelegationRequestDetails.vue'
      ),
      meta: {
        roles: [AdminRoles.GovernanceCenterEmployee],
      },
    },
    {
      path: 'seven-hundred-errors',
      name: 'SevenHundredsErrors',
      component: () => import('../views/dashboard/seven-hundred-errors/seven-hundred-errors.vue'),
      meta: {
        roles: [AdminRoles.SuperAdmin],
      },
    },
  ],
};
