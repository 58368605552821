import { getField, updateField } from 'vuex-map-fields';
import unifiedNationalNumberRequestsApi from '@/api/unified-national-number-requests.api';
import { ErrorHandler } from '@/helpers';

function handleFilterOptions(options) {
  return {
    requestId: options.requestId,
    entityName: options.entityName,
    entityType: options.entityType,
    entityStatus: options.entityStatus,
    entityCity: options.entityCity,
  };
}
const filterPattern = () => ({
  name: '',
  id: '',
});

function handleFilterOptionsUnifiedNumbersList(options) {
  return {
    requestId: options.requestId,
    entityName: options.entityName,
    entityType: options.entityType,
    entityStatus: options.entityStatus,
    entityCity: options.entityCity,
  };
}

const formatDate = (date) => {
  if (!date) {
    return undefined;
  }
  if (typeof date !== 'string') {
    return undefined;
  }
  const datePart = date.split('T')[0];
  return datePart ? datePart.replace(/-/g, '/') : undefined;
};

const normalizeLicensingInfo = (data = {}) => ({
  id: data.id || '',
  status: data.status || '',
  unifiedNumbers: data.unifiedNumbers || [],
  unifiedNumber700: data.unifiedNumber700 || '',
  licensingNumber: data.entityLicenseNumber || '',
  licenseInitiatedAt: formatDate(data.licenseInitiatedAt) || '',
  entityId: data.entityId || '',
  licenseExpiredAt: formatDate(data.licenseExpiredAt) || '',
  entityTelephoneNumber: data.entityPhoneNumber ? data.entityPhoneNumber.split('00966')[1] : '',
  entityPhoneNumber: data.entityTelephoneNumber ? data.entityTelephoneNumber.split('00966')[1] : '',
  entityEmail: data.entityEmail || '',
  chairmanBoardFirstName: data.chairmanBoardFirstName || '',
  chairmanBoardFatherName: data.chairmanBoardFatherName || '',
  chairmanBoardGrandfatherName: data.chairmanBoardGrandfatherName || '',
  chairmanBoardLastName: data.chairmanBoardLastName || '',
  chairmanBoardBirthday: formatDate(data.chairmanBoardBirthday) || '',
  chairmanBoardNationalId: data.chairmanBoardNationalId || '',
  chairmanBoardPhoneNumber: data.chairmanBoardPhoneNumber ? data.chairmanBoardPhoneNumber.split('00966')[1] : '',
  chairmanBoardEmail: data.chairmanBoardEmail || '',
  ownerFirstName: data.ownerFirstName || '',
  ownerFatherName: data.ownerFatherName || '',
  ownerGrandfatherName: data.ownerGrandfatherName || '',
  ownerLastName: data.ownerLastName || '',
  ownerNationalId: data.ownerNationalId || '',
  ownerPhoneNumber: data.ownerPhoneNumber ? data.ownerPhoneNumber.split('00966')[1] : '',
  ownerEmail: data.ownerEmail || '',
  ownerBirthday: formatDate(data.ownerBirthday) || '',
  cityAr: data.cityAr || '',
  cityEn: data.cityEn || '',
  createdAt: formatDate(data.createdAt) || '',
  nameAr: data.nameAr || '',
  nameEn: data.nameEn || '',
  type: data.type || '',
});

const state = {
  form: normalizeLicensingInfo(),
  isLoading: false,
  errors: [],
  totalRequestsCount: 0,
  searchFilter: filterPattern(),
  filterOptions: {},
  unifiedNumber700: '',

};
const getters = {
  getField,
  getDataInfo: (store) => store.form,
  getErrors: (store) => store.errors,
  isLoading: (store) => store.isLoading,
  getTotalRequestsCount: (store) => store.totalRequestsCount,
  getFilterSettings: (store) => store.searchFilter,
  getAttachments: (store) => store.attachments,
  getUnifiedNumber700: (store) => store.unifiedNumber700,
};
const mutations = {
  updateField,
  setDataInfo: (store, data) => {
    store.form = { ...store.form, ...data };
  },
  setUnifiedNumber700: (store, value) => {
    store.unifiedNumber700 = value;
  },
  setLoading: (store, value) => {
    store.isLoading = value;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  resetErrors: (store) => {
    store.errors = [];
  },
  setAttachments: (store, value) => {
    store.attachments = value;
  },
  setTotalRequestsCount: (store, value) => {
    store.totalRequestsCount = value;
  },
  resetCounts: (store) => {
    store.totalRequestsCount = 0;
  },
  setFilterOptions: (state, filterOptions) => {
    state.filterOptions = { ...handleFilterOptionsUnifiedNumbersList(filterOptions) };
  },
  setFilterOptionsUnifiedNumbersList: (state, filterOptions) => {
    state.filterOptions = { ...handleFilterOptionsUnifiedNumbersList(filterOptions) };
  },
  resetFilterOptions: (state) => {
    state.filterOptions = {
      ...handleFilterOptions({
        requestId: null,
        entityName: null,
        entityType: null,
        entityCity: null,
        entityStatus: null,
      }),
    };
  },
  resetFilterOptionsUnifiedNumbersList: (state) => {
    state.filterOptions = {
      ...handleFilterOptionsUnifiedNumbersList({
        requestId: null,
        entityName: null,
        entityType: null,
        entityCity: null,
        entityStatus: null,
      }),
    };
  },
};
const actions = {
  fetchUnifiedNumbersList: async ({ commit, rootGetters }, {
    namespace, active, status,
  },
  ) => {
    commit('setLoading', true);
    commit('setDataInfo', []);
    try {
      const {
        requestId,
        entityName,
        entityStatus,
        entityType,
        entityCity,
      } = state.filterOptions;
      const payload = {
        page: rootGetters[`${namespace}/selectedPage`],
        size: rootGetters[`${namespace}/selectedSize`],
        id: requestId,
        name: entityName,
        status: entityStatus || status,
        type: entityType,
        city: entityCity,
      };
      const res = await unifiedNationalNumberRequestsApi.getUnifiedNumberAdminRequest(payload);
      const unifiedListRequest = res?.data?.listUnifiedNumberAdminRequest;
      const filteredRequests = unifiedListRequest.adminRequests
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter((request) => request.active === active);
      const metaData = res?.data?.listUnifiedNumberAdminRequest?.meta;
      if (metaData && filteredRequests.length !== 0) {
        commit(`${namespace}/setTotalPages`, metaData.pageCount, { root: true });
        commit(`${namespace}/setTotalRecords`, metaData.recordsCount, { root: true });
        commit('setTotalRequestsCount', metaData.recordsCount);
      } else {
        commit(`${namespace}/setTotalPages`, 1, { root: true });
        commit(`${namespace}/setTotalRecords`, 0, { root: true });
        commit('setTotalRequestsCount', 0);
      }
      commit('setDataInfo', { ...unifiedListRequest, adminRequests: filteredRequests });
      commit('setLoading', false);
      return filteredRequests;
    } catch (e) {
      commit('setLoading', false);
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      commit('setDataInfo', []);
      return errObj;
    }
  },

  fetchUnifiedNumberDataById: async ({ commit, dispatch }, { id }) => {
    commit('setLoading', true);
    commit('setDataInfo', []);
    try {
      const payload = { id };
      const data = await unifiedNationalNumberRequestsApi.getUnifiedNumberAdminRequestById(payload);
      const unifiedRequestDataById = data?.data?.findUnifiedNumberAdminRequest;
      const unifiedRequestData = normalizeLicensingInfo(unifiedRequestDataById);

      commit('setDataInfo', unifiedRequestData);
      commit('setLoading', false);

      const adminRequestId = unifiedRequestData?.id;

      if (adminRequestId) {
        const lazyCalls = ['fetchUnifiedNumAttachments'];
        lazyCalls.forEach((name) => {
          dispatch(name, { page: 1, size: 15, adminRequestId });
        });
      }
      return unifiedRequestData;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      commit('setDataInfo', []);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  fetchUnifiedNumAttachments: async ({ commit }, { page, size, adminRequestId }) => {
    commit('setLoading', true);
    commit('setAttachments', []);
    try {
      const payload = {
        page,
        size,
        adminRequestId,
      };
      const data = await unifiedNationalNumberRequestsApi.getUnifiedNumberAttachments(payload);
      const attachments = data?.data?.listUnifiedNumberAttachments?.attachments;
      commit('setAttachments', attachments);
      commit('setLoading', false);
      return attachments;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      commit('setAttachments', []);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  fetchListDuplicatedEntities: async ({ commit }, { id }) => {
    // commit('setLoading', true);
    try {
      const payload = { id };
      const data = await unifiedNationalNumberRequestsApi.getListDuplicatedEntities(payload);
      const ListDuplicatedEntities = data?.data?.listDuplicatedEntities;
      // commit('setLoading', false);
      return ListDuplicatedEntities;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      // commit('setLoading', false);
    }
  },

  rejectUnifiedNumberAdminRequest: async ({ commit }, { id }) => {
    commit('setLoading', true);
    try {
      const payload = { id };
      const data = await unifiedNationalNumberRequestsApi.rejectUnifiedNumberAdminRequest(payload);
      return data;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },

  acceptUnifiedNumberAdminRequest: async ({ commit }, { id, unifiedNumber }) => {
    commit('setLoading', true);
    commit('setUnifiedNumber700', '');
    try {
      const payload = { id, unifiedNumber };
      const data = await unifiedNationalNumberRequestsApi.acceptUnifiedNumberAdminRequest(payload);
      const acceptUnifiedNumber = data?.data?.acceptUnifiedNumberAdminRequest;
      commit('setUnifiedNumber700', acceptUnifiedNumber.unifiedNumbers);
      return acceptUnifiedNumber;
    } catch (e) {
      const errObj = ErrorHandler.parseFormErrors(e);
      commit('setUnifiedNumber700', '');
      commit('setErrors', errObj.error);
      return errObj;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
