import { ErrorHandler } from '@/helpers';
import {
  acceptRequest,
  acceptRequestBySpecialized,
  fetchEntityBanks,
  fetchFamilyFundRequest,
  fetchFamilyFundSubscription,
  rejectRequest,
  rejectRequestBySpecialized,
} from '@/api/family-fund.api';
import i18n from '@/i18n';
import cond from 'lodash.cond';
import matches from 'lodash.matches';
import { AdminRoles, EstablishmentType } from '@/constants/enums';

const state = {
  familyRequest: {},
};

const getters = {
  getFamilyRequest: (store) => store.familyRequest,
};

const mutations = {
  setFamilyRequest(store, request) {
    store.familyRequest = request;
  },
};
const actions = {
  fetchFamilyFundRequest: async ({ commit, dispatch, rootGetters }, requestId) => {
    try {
      const adminRole = rootGetters['auth/adminRole'];
      const res = await fetchFamilyFundRequest(requestId);
      let request = res?.data?.findEstablishmentAdminRequest;
      const recommendationMethod = await dispatch('getAdminRecommendationMethod', request?.unit?.id);
      const subscriptionPromise = (adminRole !== AdminRoles.Specialized && adminRole !== AdminRoles.MinistryOfInteriorEmployee) ? dispatch('findFamilyFundSubscription', request?.unit?.id) : null;
      const banksPromise = (adminRole !== AdminRoles.Specialized && adminRole !== AdminRoles.MinistryOfInteriorEmployee) ? dispatch('listEntityBanks', request?.unit?.id) : null;
      const userRequestsPromise = dispatch('requests/fetchMemberships', { entityId: request?.unit?.id, unitType: EstablishmentType.Entity }, { root: true });
      const recommendationPromise = recommendationMethod({ adminRole });
      const [banks, subscription, recommendations] = await Promise.all(
        [banksPromise, subscriptionPromise, recommendationPromise, userRequestsPromise],
      );
      if (recommendations && !recommendations.error) {
        request = { ...request, recommendations };
      }
      if (banks && !banks.error) {
        request = { ...request, banks };
      }
      if (subscription && !subscription.error) {
        request = { ...request, subscription: subscription?.amount };
      }
      commit('setFamilyRequest', request);
      await dispatch('requests/fetchCheckMinistryOfInteriorRequestDetails', request?.unit?.id, { root: true });
      return request;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  getAdminRecommendationMethod: ({ dispatch }, entityId) => cond([
    [matches({ adminRole: AdminRoles.RegistrationAndPermitManager }),
      () => dispatch('requests/fetchAdminRecommendation', {
        entityId,
        adminRole: [
          AdminRoles.RegistrationAndPermitEmployee,
        ],
      }, { root: true }),
    ],
    [matches({ adminRole: AdminRoles.RegistrationAndPermitAdmin }),
      () => dispatch('requests/fetchAdminRecommendation', {
        entityId,
        adminRole: [
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitManager,
        ],
      }, { root: true }),
    ],
    [matches({ adminRole: AdminRoles.RegistrationAndPermitCeo }),
      () => dispatch('requests/fetchAdminRecommendation', {
        entityId,
        adminRole: [
          AdminRoles.RegistrationAndPermitEmployee,
          AdminRoles.RegistrationAndPermitAdmin,
          AdminRoles.RegistrationAndPermitManager,
        ],
      }, { root: true }),
    ],
  ]),
  findFamilyFundSubscription: async (_, id) => {
    try {
      const res = await fetchFamilyFundSubscription(id);
      return res?.data?.findFamilyFundSubscriptionByEntityIdRequest;
    } catch (e) {
      return {
        error: ErrorHandler.parseGlobalErrors(e),
      };
    }
  },
  listEntityBanks: async (_, id) => {
    try {
      const res = await fetchEntityBanks(id, EstablishmentType.Entity);
      return res.data?.entitiesBanks;
    } catch (e) {
      return { error: e.message };
    }
  },
  acceptRequest: async ({ dispatch }, { id, message }) => {
    try {
      const res = await acceptRequest(id, message);
      dispatch('toast/showNotification', {
        message: i18n.t('general.requestHasBeenSent'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return res.data?.acceptEstablishmentAdminRequest;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  rejectRequest: async ({ dispatch }, { id, message }) => {
    try {
      const res = await rejectRequest(id, message);
      dispatch('toast/showNotification', {
        message: i18n.t('general.requestHasBeenSent'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return res.data?.rejectEstablishmentAdminRequest;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  sendMemberRequestStatus: async ({ commit }, { requestId, status }) => {
    try {
      const method = status === 'accept' ? acceptRequestBySpecialized : rejectRequestBySpecialized;
      const res = await method(requestId);
      const data = status === 'accept' ? res.data?.acceptBySpecializedUserRequest : res.data?.rejectBySpecializedUserRequest;
      commit('requests/setStatus', { requestId, status: data?.status }, { root: true });
      return data;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
