import Vue from 'vue';
import Vuex from 'vuex';
import uiModule from './ui';
import toastModule from './toast';
import authModule from './auth';
import adminsModule from './admins';
import requestsModule from './requests';
import lookupModule from './lookup';
import referencesModule from './references';
import documentsModule from './documents';
import editPolicyModule from './edit-policy';
import employeeModule from './employee';
import bankAccountModule from './bank-account';
import activitiesModule from './activities';
import eshopRequestsModule from './eshop';
import bankDelegationsModule from './bank-delegations';
import updatingEntitiesRequestsModule from './updating-entities-requests';
import familyFundRequestModule from './family-fund-request';
import editRequestModule from './edit-request';
import entitiesModule from './entities';
import entityProfileModule from './entity-profile';
import objectionRequestsModule from './objection-request';
import downloadListModule from './download-list';
import bankDelegationRequestsModule from './bank-delegation-requests';
import unifiedNationalNumberModule from './unified-national-number-requests';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  strict: debug,
  modules: {
    ui: uiModule,
    toast: toastModule,
    auth: authModule,
    requests: requestsModule,
    lookup: lookupModule,
    references: referencesModule,
    documents: documentsModule,
    admins: adminsModule,
    editPolicy: editPolicyModule,
    employee: employeeModule,
    bankAccount: bankAccountModule,
    activities: activitiesModule,
    eshopRequests: eshopRequestsModule,
    bankDelegations: bankDelegationsModule,
    updatingEntitiesRequests: updatingEntitiesRequestsModule,
    familyFundRequest: familyFundRequestModule,
    editRequest: editRequestModule,
    entities: entitiesModule,
    entityProfile: entityProfileModule,
    objectionRequests: objectionRequestsModule,
    downloadList: downloadListModule,
    bankDelegationRequests: bankDelegationRequestsModule,
    unifiedNationalNumberData: unifiedNationalNumberModule,
  },
});

window.logStoreMutations = () => {
  store.subscribe(({ type, payload }, state) => {
    console.log(
      `%c 🧟‍♀️ Mutation:   ${type} `,
      'background: #333; color: #bada55',
      {
        payload,
        state,
      },
    );
  });
};

window.logStoreActions = () => {
  store.subscribeAction(({ type, payload }, state) => {
    console.log(
      `%c 🎬 Action:     ${type} `,
      'background: #333; color: #54afbd',
      {
        payload,
        state,
      },
    );
  });
};

export default store;
