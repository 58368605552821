import { GraphQLRequest } from '@/setup/axios';
import {
  ACCEPTED_UNIFIED_NUMBER_ADMIN_REQUEST,
  FETCh_LIST_DUPLICATED_ENTITIES,
  FETCh_UNIFIED_NUMBER_ADMIN_REQUEST,
  FETCh_UNIFIED_NUMBER_ADMIN_REQUEST_BY_ID,
  FETCH_UNIFIED_NUMBER_ATTACHMENTS,
  REJECTED_UNIFIED_NUMBER_ADMIN_REQUEST,
} from './graphql/unified-national-number-requests-gql';

class UnifiedNationalNumberRequestsApi {
    getUnifiedNumberAdminRequest = (payload) => (
      GraphQLRequest({
        query: FETCh_UNIFIED_NUMBER_ADMIN_REQUEST,
        variables: {
          page: Number(payload.page),
          size: Number(payload.size),
          entityId: payload.entityId ? Number(payload.entityId) : null,
          status: payload.status ? payload.status : null,
          name: payload.name ? payload.name : null,
          type: payload.type ? payload.type : null,
          city: payload.city ? payload.city : null,
          id: payload.id ? Number(payload.id) : null,
        },
      })
    );

    getUnifiedNumberAdminRequestById = (payload) => (
      GraphQLRequest({
        query: FETCh_UNIFIED_NUMBER_ADMIN_REQUEST_BY_ID,
        variables: {
          id: payload.id ? Number(payload.id) : null,
        },
      })
    );

    getUnifiedNumberAttachments = (payload) => GraphQLRequest({
      query: FETCH_UNIFIED_NUMBER_ATTACHMENTS,
      variables: {
        page: Number(payload.page),
        size: Number(payload.size),
        adminRequestId: payload.adminRequestId ? Number(payload.adminRequestId) : null,
      },
    });

    rejectUnifiedNumberAdminRequest = (payload) => GraphQLRequest({
      query: REJECTED_UNIFIED_NUMBER_ADMIN_REQUEST,
      variables: {
        id: payload.id ? Number(payload.id) : null,
      },
    });

    acceptUnifiedNumberAdminRequest = (payload) => GraphQLRequest({
      query: ACCEPTED_UNIFIED_NUMBER_ADMIN_REQUEST,
      variables: {
        id: payload.id ? Number(payload.id) : null,
        unifiedNumber: payload.unifiedNumber ? payload.unifiedNumber : null,
      },
    });

    getListDuplicatedEntities = (payload) => GraphQLRequest({
      query: FETCh_LIST_DUPLICATED_ENTITIES,
      variables: {
        entityUid: payload.id ? payload.id : null,
      },
    });
}

export default new UnifiedNationalNumberRequestsApi();
