export const FETCh_UNIFIED_NUMBER_ADMIN_REQUEST = ` query ListUnifiedNumberAdminRequest($page: Int!, $size: Int!,$id:Int, $status: String, $entityId: Int , $name: String , $city: String , $type: String) {
  listUnifiedNumberAdminRequest(page: $page, size: $size, id: $id, status: $status, entityId: $entityId , name: $name , city: $city , type: $type) {
      adminRequests {          
          id
          unifiedNumbers
          nameAr
          nameEn
          status
          entityId
          createdAt
          active
      }
       meta {
        page
        pageCount
        recordsCount
        size
      }
  }
}`;
export const FETCh_UNIFIED_NUMBER_ADMIN_REQUEST_BY_ID = ` query FindUnifiedNumberAdminRequest($id: Int!) {
  findUnifiedNumberAdminRequest(id: $id) {
      chairmanBoardBirthday
        chairmanBoardEmail
        chairmanBoardFatherName
        chairmanBoardFirstName
        chairmanBoardGrandfatherName
        chairmanBoardLastName
        chairmanBoardNationalId
        chairmanBoardPhoneNumber
        cityAr
        cityEn
        createdAt
        entityEmail
        entityId
        entityPhoneNumber
        entityTelephoneNumber
        id
        licenseExpiredAt
        licenseInitiatedAt
        nameAr
        nameEn
        ownerBirthday
        ownerEmail
        ownerFatherName
        ownerFirstName
        ownerGrandfatherName
        ownerLastName
        ownerNationalId
        ownerPhoneNumber
        status
        type
        unifiedNumbers
        updatedAt
  }
}`;

export const FETCH_UNIFIED_NUMBER_ATTACHMENTS = `query ListUnifiedNumberAttachments ($page:Int!,$size:Int!,$adminRequestId:Int!){
  listUnifiedNumberAttachments(page:$page,size:$size,adminRequestId:$adminRequestId){
      attachments {
          unifiedNumber
          adminRequestId
          fileMetadataUid
          docType
          attachments {
            filename
            path
            uid
            docType
          }
      }
  }
}`;

export const ACCEPTED_UNIFIED_NUMBER_ADMIN_REQUEST = `mutation AcceptUnifiedNumberAdminRequest ($unifiedNumber:String!, $id:  Int!) {
    acceptUnifiedNumberAdminRequest(unifiedNumber:$unifiedNumber,id:$id) {
        entityId
        id
        unifiedNumbers

    }
}`;

export const REJECTED_UNIFIED_NUMBER_ADMIN_REQUEST = `mutation RejectUnifiedNumberAdminRequest($id:Int!){
  rejectUnifiedNumberAdminRequest(id:$id) {
      chairmanBoardBirthday
      chairmanBoardEmail
      chairmanBoardFatherName
      chairmanBoardFirstName
      chairmanBoardGrandfatherName
      chairmanBoardLastName
      chairmanBoardNationalId
      chairmanBoardPhoneNumber
      cityAr
      cityEn
      createdAt
      entityEmail
      entityId
      entityPhoneNumber
      entityTelephoneNumber
      id
      licenseExpiredAt
      licenseInitiatedAt
      nameAr
      nameEn
      ownerBirthday
      ownerEmail
      ownerFatherName
      ownerFirstName
      ownerGrandfatherName
      ownerLastName
      ownerNationalId
      ownerPhoneNumber
      status
      type
      unifiedNumbers
      updatedAt
  }
}`;

export const FETCh_LIST_DUPLICATED_ENTITIES = ` query ListDuplicatedEntities($entityUid: String!) {
  listDuplicatedEntities(entityUid: $entityUid) {     
        entityUid
        nameArabic
        nameEnglish
        registrationDateHijri
        unifiedNumber700
        createdAt
        entityLicenseNumber
        id
        type
  }
}`;
